import { ErrorOutline, InfoOutlined, Warning } from '@mui/icons-material';
import { Box, CircularProgress, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { snackCaller } from '../App';
import util from '../util';

function TextWithTooltip(props) {

    return (
        <Tooltip title={props.title} arrow>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1.75
                }}
            >
                <Typography {...props} />
                <InfoOutlined fontSize="small" />
            </Box>
        </Tooltip>
    );
}

export default TextWithTooltip;