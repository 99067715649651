import { Error, Warning } from '@mui/icons-material';
import { Typography, Link, Box, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import mokouError from "../images/errmokou.webp"
import Image from './Image';

const ErrorPage = (props) => {

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                textAlign: "center",
                fontFamily: "Montserrat"
            }}
        >
            <Tooltip title="Click to view source" arrow>
                <img
                    src={require("../images/errmokou.webp")} style={{ width: 200, height: 200, marginBottom: 20, cursor: "pointer" }}
                    onClick={() => {
                        // open the image source in a new tab
                        window.open("https://gelbooru.com/index.php?page=post&s=view&id=9361545", "_blank")
                    }}
                />
            </Tooltip>
            <Typography
                variant="h3"
                component="h1"
                sx={{
                    fontFamily: "Montserrat"
                }}
            >
                Something went wrong...
            </Typography>

            <Typography
                variant="h5"
                component="h1"
                sx={{
                    fontFamily: "Montserrat"
                }}
            >
                {props.message}
            </Typography>
        </Box>
    );
};

export default ErrorPage;