import { ErrorOutline, Warning } from '@mui/icons-material';
import { CircularProgress, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { snackCaller } from '../App';
import util from '../util';


/*
function Video(props) {
    const { esrc, ...other } = props;
    const [error, setError] = React.useState(null);
    const [dsrc, setDsrc] = React.useState(null);

    React.useEffect(() => {
        if (!esrc && !props.src) {
            return;
        }

        if (dsrc) {
            URL.revokeObjectURL(dsrc);
        }

        const cacheName = 'video-cache';

        async function fetchAndCacheImage(url) {
            const cache = await caches.open(cacheName);
            const cachedResponse = await cache.match(url);

            if (cachedResponse) {
                return cachedResponse;
            }

            const networkResponse = await fetch(url);
            if (networkResponse.ok) {
                try {
                    cache.put(url, networkResponse.clone());
                } catch (e) { }
                return networkResponse;
            } else {
                throw new Error('Network response was not ok');
            }
        }

        fetchAndCacheImage(props.src).then(async (res) => {
            setDsrc(URL.createObjectURL(await res.blob()));
        }).catch((e) => {
            setError('Error loading video');
        });
    }, [props.src, esrc]);

    if (!props.src) {
        return (
            <></>
        )
    }

    if (!dsrc && !error) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...props.style
                }}
            >
                <CircularProgress size={40} color="primary" />
            </div>
        )
    }

    if (error) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    fontSize: 50
                }}
            >
                <Warning
                    color="error"
                    fontSize="inherit"
                />
            </div>
        )
    }

    return (
        <video src={dsrc} {...other} />
    );
}*/
/*
function Video(props) {
    return (
        <video src={props.src} {...props} />
    );
}*/

function Video(props) {
    const [error, setError] = React.useState(false);

    useEffect(() => {
        setError(false);
    }, [props.src]);

    if (error) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    fontSize: 50,
                    gap: 20
                }}
            >
                <Warning
                    color="error"
                    fontSize="inherit"
                />
                <Typography
                    variant="h6"
                    color="error"
                >
                    Error loading video
                </Typography>
            </div>
        )
    }

    return (
        <video src={props.src} {...props}
            onError={(e) => {
                console.log(e)
                setError(true);
                snackCaller("Error loading video");
            }} />
    );
}

export default Video;