import Button from '@mui/material/Button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import util from '../../util';
import { Typography, Box, Tabs, Tab } from '@mui/material';
import Markdown from '../../components/Markdown';

const docs = {
    "welcome": `
# Welcome to the Vorecade API Documentation!
***PLEASE NOTE THAT THIS API IS PRIMARILY INTENDED FOR ANALYTICS USE IN GAMES HOSTED ON VORECADE AT THE TIME BEING. THE API WON'T RETURN ANY INFORMATION REGARDING USERS.***

This documentation is intended to help you understand how to use the Vorecade API to interact with the Vorecade platform. The Vorecade API is a RESTful API that allows you to interact with the Vorecade platform programmatically. This documentation will cover the various endpoints available in the Vorecade API, as well as the different types of requests that can be made to these endpoints. If you have any questions or need further assistance, please don't hesitate to contact us on our [Discord server](https://discord.gg/fvjfp8NJMg).

You can use the navigation bar at the top of the page to browse the different sections of this documentation.

## API Base URL
The base URL for the Vorecade API is \`https://vorecade.com\`

Every example in this documentation assumes that you are using this base URL to make requests to the Vorecade API.
Example: \`https://vorecade.com/api/...\`

Enjoy using the Vorecade API!
`,
    "authenticating clients": `
# Authenticating API Clients

Every API request made to the API must be authenticated and be correlated with a specific clientID. This is to help analyze and monitor the usage statistics of the whole site. clientIDs are UUIDs in the format of UUIDv4. They do not contain personally identifiable information and must be generated on the client side registering a new clientID.

### What if I don't provide a clientID/invalid clientID?
It'll be discarded. The server will simply not process your request, and you'll receive a 400 Bad Request status code.

## When to register a new clientID?
You can register a new clientID and hardcode it in your application if you'd like, but it's recommended to generate a new clientID for every unique user. This is to help us better understand the usage patterns of the API and to help us improve the service.

You should **NEVER** register a new clientID every time your application starts or every time you send a request. This will flood the database with unnecessary clientIDs and may result in your requests being rate-limited or blocked.

### What's the rate limit?
The rate limit should be more than enough for most applications. If you're hitting the rate limit, you're probably doing something wrong. If you legitimately need a higher rate limit, please contact us on our Discord server.

## How do I register a clientID?
You can register a clientID by sending a POST request as follows:

\`\`\`
POST /api/scii
Content-Type: application/json

{
    "PCWUUID": "YOUR_RANDOMLY_GENERATED_UUIDV4",
    "platform": "web" // MUST BE SET TO "web" FOR API CLIENTS
}
\`\`\`
*SCII stands for "SEND CLIENT IDENTIFICATION INFORMATION"*

Replace \`YOUR_RANDOMLY_GENERATED_UUIDV4\` with a UUIDv4 generated on the client side.

### Example
\`\`\`
POST /api/scii
Content-Type: application/json

{
    "PCWUUID": "f47ac10b-58cc-4372-a567-0e02b2c3d479",
    "platform": "web"
}
\`\`\`

### Response

If the clientID is successfully registered, the server will respond with a 200 OK status code and a JSON object.

\`\`\`
{
    "message": "ok",
}
\`\`\`

### Error Responses

If the request is sent with an invalid platform, the server will respond with a 400 Bad Request status code and a JSON object.
\`\`\`
{
    "message": "Invalid platform",
}
\`\`\`

If the PCWUUID field in the request is empty, the server will respond with a 400 Bad Request status code and a JSON object.
\`\`\`
{
    "message": "Missing fields"
}
\`\`\`
`,
    "custom analytics": `
# Custom Analytics Events

The Vorecade API allows you to send custom analytics events to the Vorecade platform. These events can be used to track user interactions within your games or anything else you want to track.

## Creating a Custom Event Schema
Before you start sending custom events, you need to create a custom event schema. This schema defines the structure of the custom events you want to send. You can create a custom event schema by using the Vorecade app.

**Content Manager > Game Analytics > Your Game > Custom Events**

Follow the instructions on the page to create a custom event schema. Please do note that the schema must be created before you can start sending custom events.

### Important Note on Creating Custom Events
- Event names must be unique within a game.
- You should **ALWAYS** use your eventID/UUID when sending events for the most accurate data.
- Events **cannot** be deleted once created.
- Event names are case-sensitive.
- Event property names are case-sensitive.
- Event validation failures will result in the incoming event being discarded.
- You can add new properties to an existing event schema, but you cannot remove or modify existing properties.

## Sending Custom Events
Once you have created a custom event schema, you can start sending custom events to the Vorecade platform. You can send custom events by making a POST request to the following endpoint:


***You can get your event UUID from the Vorecade app.***

\`\`\`
POST /api/scen
Content-Type: application/json

{
    "PCWUUID": "YOUR_CLIENT_ID",
    "eventID": "YOUR_EVENT_UUID",
    "data": {
        "stringProperty": "someString",
        "numberProperty": 69,
        "booleanProperty": true
        ...
    }
}
\`\`\`

### Example

\`\`\`
POST /api/scen
Content-Type: application/json

{
    "PCWUUID": "f47ac10b-58cc-4372-a567-0e02b2c3d479",
    "eventID": "f47ac10b-58cc-4372-a567-0e02b2c3d490",
    "data": {
        "level": 1,
        "score": 100
    }
}
\`\`\`

### Response

If the custom event is successfully sent, the server will respond with a 200 OK status code and a JSON object.

\`\`\`
{
    "message": "ok"
}
\`\`\`

### Error Responses

If the request is sent with an invalid clientID, the server will respond with a 400 Bad Request status code and a JSON object.

\`\`\`
{
    "message": "Client not found"
}
\`\`\`

If the request is sent with an invalid event values such as a string in a number field, a non existent property field etc. the server will respond with a 400 Bad Request status code and a JSON object.

\`\`\`
{
    "message": "Error explaining what was expected and what was received"
}
\`\`\`

If the request is sent with an event name that does not exist, the server will respond with a 400 Bad Request status code and a JSON object.

\`\`\`
{
    "message": "No schema found for event EVENT_NAME"
}
\`\`\`

## Viewing Custom Event Analytics

You can view custom event analytics in the Vorecade app. Go to **Content Manager > Game Analytics > Your Game > Custom Events > Custom Event Graphs** to view custom event analytics for your game. You can even filter by the day and see individual event payload data too!

## More Information

- Enums (Predefined values) for strings can be sent as you would send a regular string value.
- Numbers can only be sent as integers, floats are not supported.
- Boolean values can only be true or false. Sending any other value will result in a validation error and the fabric of reality being torn apart.
- You can send any number of properties with your custom event, but you should only send the properties that are defined in the custom event schema.

`
}

const Home = () => {
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(0);

    function CustomTabPanel(props) {
        const { children, value, index, cs, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3, width: "100%", ...cs }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currentTab} onChange={(e, v) => {
                    setCurrentTab(v);
                }}>
                    {Object.keys(docs).map((doc, i) => {
                        return <Tab key={i} label={doc} />
                    })}
                </Tabs>

                {Object.keys(docs).map((doc, i) => {
                    return (
                        <CustomTabPanel key={i} value={currentTab} index={i} cs={{}}>
                            <div
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                <Markdown markdown={docs[doc]} />
                            </div>
                        </CustomTabPanel>
                    );
                })}
            </Box>
        </Box>
    );
};

export default Home;