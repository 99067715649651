import * as App from './App';
import { useNavigate } from 'react-router-dom';

export function isElectron() {
    let isEl = (() => {
        // Renderer process"
        if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
            return true;
        }

        // Main process
        if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
            return true;
        }
    })();

    return isEl;
}

function isDevMode() {
    const isDev = window.NODE_ENV === 'development';
    return !isDev;
}

export let functionRoot = ""

// Usage
if (isElectron()) {
    console.log('Running inside Electron');
    if (isDevMode() || process.env.NODE_ENV === 'development') {
       functionRoot = "https://vorecade.com" // USE LATEST, USE LOCALHOST FOR TESTING
    } else {
        functionRoot = "https://vorecade.com"
    }
} else {
    functionRoot = process.env.REACT_APP_API_BASE_URL
}

function base64urlToArrayBuffer(base64url) {
    const base64 = base64url.replace(/-/g, '+').replace(/_/g, '/');
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

let isConnected = false
let retries = 0

async function awaitConnection() {
    setTimeout(() => {
        if (!isConnected) {
            App.loadingCaller(true)
        }
    }, 3000);
    while (!isConnected) {
        if (retries === 3) {
            alert("Vorecade couldn't connect to the server.")
            break
        }
        await new Promise((res) => setTimeout(() => {
            fetch(`${functionRoot}/204`).then((res) => {
                if (res.status === 204) {
                    isConnected = true
                    App.loadingCaller(false)
                    return res()
                }
                retries++
                res()
            }).catch(e => {
                console.log(`cant gen 204\n\n${e}`)
                retries++
                res()
            })
        }, 1000))
    }
}

export function postRequest(url, data, progressCallback, contentType = 'application/json', nR = false) {
    return new Promise((resolve, reject) => {
        let loadcaller = setTimeout(() => {
            App.callLoadingLine(0, "Waiting for server...")
        }, 1000);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${functionRoot}${url}`, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("token") || '');

        if (!(data instanceof FormData)) {
            xhr.setRequestHeader('Content-Type', contentType);
            data = JSON.stringify(data);
        }

        xhr.upload.onprogress = function (event) {
            if (event.lengthComputable) {
                const percentCompleted = Math.round((event.loaded * 100) / event.total);
                progressCallback && progressCallback(percentCompleted);
            }
        };

        xhr.onload = function () {
            App.callLoadingLine(-1, "")
            clearTimeout(loadcaller);
            let response;

            try {
                response = JSON.parse(this.response);
            } catch (e) {
                response = {};
            }

            if (this.status === 200) {
                response.token && localStorage.setItem("token", response.token);
                response.user && localStorage.setItem("user", JSON.stringify(response.user));

                if (response.redirect && !nR) {
                    App.navigate(response.redirect);
                }

                if (response.message && url !== "/api/scii" && url !== "/api/scen") {
                    App.snackCaller(response.message);
                }

                resolve(response);
            } else {
                if (response.redirect && !nR) {
                    App.navigate(response.redirect);
                }

                if (response.message && !nR) {
                    App.snackCaller(response.message);
                }

                reject({ message: this.statusText });
            }
        };

        xhr.onerror = function () {
            reject({ message: this.statusText });
        };

        xhr.send(data);
    });
}

export default {
    isElectron: isElectron,
    isAuthenticated: function () {
        return fetch(`${functionRoot}/auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token") || ''
            },
            credentials: 'include'
        }).then((response) => {
            if (response.ok) {
                return true
            } else {
                return false
            }
        }).catch((error) => {
            return false
        })
    },
    postRequest: postRequest,
    getRequest: function (url, cb, dna = false) { // dna = Do Not Authenticate - rD = race data
        if (url === "/204") dna = true;

        let loadcaller = setTimeout(() => {
            App.callLoadingLine(0, "Waiting for server...")
        }, 1000);

        return fetch(`${functionRoot}${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': dna ? "" : 'Bearer ' + localStorage.getItem("token") || ''
            },
            credentials: dna ? "omit" : "include",
        }).then(async (res) => {
            App.callLoadingLine(-1, "")
            clearTimeout(loadcaller);
            if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
            }

            if (res.status === 204) {
                return { code: 204 }
            }

            let response
            try {
                response = await res.json()
            } catch (e) {
                throw e; // Re-throw the error so it can be caught by the catch block
            }

            if (res.status === 401) {
                App.snackCaller(response.message || "Unauthorized. Invalid username or password")
                return { code: 401 }
            }
            if (res.status === 500) {
                App.snackCaller("An error occurred. Please try again later.")
                return { code: 500 }
            }

            response.token && localStorage.setItem("token", response.token)
            response.user && localStorage.setItem("user", JSON.stringify(response.user))

            if (response.redirect) {
                App.navigate(response.redirect)
            }

            if (response.message) App.snackCaller(response.message)

            cb && cb(response)

            return response
        }).catch((error) => {
            App.callLoadingLine(-1, "")
            clearTimeout(loadcaller);
            console.error('Error in getRequest:', error);
            return { message: error?.message || "An error occurred. Please try again later." }
        });
    },
    getUserRank: function (rankID) {
        const ranks = {
            0: "Banned",
            1: "User",
            2: "Artist",
            3: "Game Developer",
            4: "Moderator",
            5: "Administrator",
            6: "Developer"
        }

        return ranks[rankID] || "Unknown"
    },
    decrypt: async function (esrc, setError) {
        let key, iv;

        if (localStorage.getItem(esrc)) {
            const stored = JSON.parse(localStorage.getItem(esrc));
            key = base64urlToArrayBuffer(stored.key);
            iv = base64urlToArrayBuffer(stored.iv);
        } else {
            let res = await fetch(`${functionRoot}/content/getEncryptionKey`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ path: esrc })
            })

            res = await res.json();

            if (res.error) {
                setError(res.error);
                return;
            }

            key = base64urlToArrayBuffer(res.key);
            iv = base64urlToArrayBuffer(res.iv);
            localStorage.setItem(esrc, JSON.stringify({ key: res.key, iv: res.iv }));
        }

        const response = await fetch(esrc);
        let buffer = await response.arrayBuffer();

        const cryptoKey = await window.crypto.subtle.importKey(
            'raw',
            key,
            { name: 'AES-CBC', length: 256 },
            false,
            ['decrypt']
        );

        const decrypted = await window.crypto.subtle.decrypt(
            { name: 'AES-CBC', iv: iv },
            cryptoKey,
            buffer
        ).catch((error) => {
            console.error('Decryption error:', error);
        });

        const conversionMap = {
            "png": "image/png",
            "jpg": "image/jpeg",
            "jpeg": "image/jpeg",
            "webp": "image/webp",
            "gif": "image/gif",
            "mp4": "video/mp4",
            "webm": "video/webm",
            "mov": "video/quicktime",
            "avi": "video/x-msvideo",
            "mkv": "video/x-matroska",
            "apng": "image/apng",
            "flv": "video/x-flv",
            "wmv": "video/x-ms-wmv",
            "m4v": "video/x-m4v"
        };

        let ext = esrc.split('.').pop();
        let type = conversionMap[ext] || 'application/octet-stream';

        const blob = new Blob([new Uint8Array(await decrypted)], { type: type });
        const url = URL.createObjectURL(blob);
        return url;
    },
    async handleFileDrop(event, isVisible, parent) {
        event.preventDefault();

        // Handle the dropped file
        const file = event.target.files[0];
        if (!file) return;

        const CHUNK_SIZE = 20 * 1024 * 1024; // 20MB
        let result;
        let start = 0;
        let fID = (Math.random() * 1000000).toString()

        while (start < file.size) {
            const chunk = file.slice(start, start + CHUNK_SIZE);

            const formData = new FormData();
            formData.append('file', chunk);
            formData.append('visible', isVisible || false);
            formData.append('parent', parent || null);
            formData.append("isChunked", file.size > CHUNK_SIZE);
            formData.append("isLast", start + CHUNK_SIZE >= file.size); // Check if it's the last chunk
            formData.append("name", file.name);
            formData.append("vcid", fID)

            try {
                result = await this.postRequest("/api/content/upload", formData);
                console.log('Chunk upload complete');
            } catch (err) {
                console.error(err);
                result = { error: err };
            }

            start += CHUNK_SIZE;
        }

        return result;
    },
    Functionify: function (Comp, props) {
        let navigate = useNavigate();
        return <Comp {...props} navigate={navigate} />
    },
    analyticsHandler: function (e, data) {
        if (this.isElectron()) { // use IPC-SCEN for electron
            window.ipcRenderer.invoke('SCEN', {
                event: e,
                data: data
            });
        } else { // XHR-SCEN for web
            if (!localStorage.getItem('PCWUUID')) {
                this.generatePCWUUID();
            }

            let eve = e;

            this.postRequest('/api/scen', {
                event: e,
                data: data,
                PCWUUID: localStorage.getItem('PCWUUID')
            }).catch(e => {
                // try SCII again

                if (!localStorage.getItem('PCWUUID')) {
                    this.generatePCWUUID();
                }

                this.postRequest("/api/scii", {
                    event: "appStart",
                    PCWUUID: localStorage.getItem('PCWUUID') || this.generatePCWUUID(),
                    platform: "web"
                }).catch((e) => {
                    console.log("Error in SCII!!!", e)
                })
                    .then(() => {
                        this.postRequest('/api/scen', {
                            event: eve,
                            data: data,
                            PCWUUID: localStorage.getItem('PCWUUID')
                        }).catch(e => {
                            console.log("Error in SCEN!!!", e)
                        })
                    })


            })
        }
    },
    generatePCWUUID: function () {
        function _randomByte() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }
        const uuid = _randomByte() + _randomByte() + '-' + _randomByte() + '-' + '4' + _randomByte().substr(0, 3) + '-' + (Math.floor(Math.random() * 4 + 8)).toString(16) + _randomByte().substr(0, 3) + '-' + _randomByte() + _randomByte() + _randomByte();
        localStorage.setItem('PCWUUID', uuid);
        return uuid;
    },
    randomString: function (length) {
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = '';
        for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    },
    isURL: function (string) {
        var protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;

        var localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/
        var nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;

        if (typeof string !== 'string') {
            return false;
        }

        var match = string.match(protocolAndDomainRE);
        if (!match) {
            return false;
        }

        var everythingAfterProtocol = match[1];
        if (!everythingAfterProtocol) {
            return false;
        }

        if (localhostDomainRE.test(everythingAfterProtocol) ||
            nonLocalhostDomainRE.test(everythingAfterProtocol)) {
            return true;
        }

        return false;
    },
    base64ToBlob: function (base64String, contentType) {
        const byteCharacters = atob(base64String);
        const byteLength = byteCharacters.length;
        const sliceSize = 1024;
        const byteArrays = [];

        for (let i = 0; i < byteLength; i += sliceSize) {
            const slice = byteCharacters.substring(i, i + sliceSize);
            const byteArray = new Uint8Array(slice.length);
            for (let j = 0; j < slice.length; j++) {
                byteArray[j] = slice.charCodeAt(j);
            }
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return URL.createObjectURL(blob);
    },
    generateRandomAccessKey: function (length = 16) {
        const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = 'VCDE-';
        for (let i = length; i > 0; --i) {
            if (i % 4 === 0 && i !== length) result += '-';
            result += chars[Math.floor(Math.random() * chars.length)];
        }
        return result;
    },
    returnCurrentDynamicURL: function () {
        if(isElectron()) {
            // hashrouting
            return window.location.hash.split("#")[1].split("/")
        } else {
            // regular routing
            return window.location.href.split(window.location.hostname)[1].split("/")
        }
    }
}

// leaving this here for now, maybe localstorage is just enough?
const IDB_NAME = 'vcadeDB';
const IDB_STORE = 'appData';

function openDB() {
    return new Promise((resolve, reject) => {
        const request = window.indexedDB.open(IDB_NAME, 1);

        request.onsuccess = (event) => {
            resolve(event.target.result);
        };

        request.onerror = (event) => {
            reject(event.target.error);
        };

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            db.createObjectStore(IDB_STORE);
        };
    });
}

async function syncSet(key, value) {
    const db = await openDB();
    const transaction = db.transaction(IDB_STORE, 'readwrite');
    const store = transaction.objectStore(IDB_STORE);
    await store.put(value, key);
    return Promise.resolve();
}

async function syncGet(key) {
    const db = await openDB();
    const transaction = db.transaction(IDB_STORE, 'readonly');
    const store = transaction.objectStore(IDB_STORE);
    const value = await store.get(key);
    return Promise.resolve(value);
}

async function syncRemove(key) {
    const db = await openDB();
    const transaction = db.transaction(IDB_STORE, 'readwrite');
    const store = transaction.objectStore(IDB_STORE);
    await store.delete(key);
    return Promise.resolve();
}

async function syncClear() {
    const db = await openDB();
    const transaction = db.transaction(IDB_STORE, 'readwrite');
    const store = transaction.objectStore(IDB_STORE);
    await store.clear();
    localStorage.setItem("ageCheck", "true")
    return Promise.resolve();
}