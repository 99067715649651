import { Component } from "react";
import reactGfm from "remark-gfm";
import remarkRehype from "remark-rehype";
import rehypeHighlight from "rehype-highlight";
import rehypeVideo from 'rehype-video';
import sanitize from "rehype-sanitize";
import rehypeRaw from "rehype-raw";
import RMarkdown from "react-markdown";
import Image from "./Image";

class Markdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            markdown: ""
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.markdown !== nextProps.markdown) {
            return true;
        }

        return false;
    }

    componentDidMount() {
        this.setState({
            markdown: this.props.markdown
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.markdown !== prevProps.markdown) {
            this.setState({
                markdown: this.props.markdown
            });
        }
    }

    render() {
        return (
            <RMarkdown
                children={this.state.markdown}
                skipHtml={false}
                remarkPlugins={[reactGfm, remarkRehype]}
                rehypePlugins={[rehypeHighlight, rehypeVideo, rehypeRaw, sanitize]}
                style={{
                    width: "50%",
                    ...this.props.style
                }}
                components={{
                    img: ({ node, ...props }) => {
                        return <Image key={props.src} {...props} style={{ maxWidth: "100%" }} />;
                    },
                    video: ({ node, ...props }) => {
                        return <video {...props} controls style={{ maxWidth: "100%" }} />;
                    },
                    a: ({ node, ...props }) => { // open links in new tab
                        return <a {...props} target="_blank" rel="noopener noreferrer" />;
                    },
                    code: ({ node, inline, className, children, ...props }) => {
                        const match = /language-(\w+)/.exec(className || '');
                        return !inline && match ? (
                            <pre style={{
                                wordBreak: "break-all",
                                overflow: "auto", // Ensure overflow is handled
                                display: "block", // Ensure pre element fits within container
                            }} className={className} {...props}>
                                <code style={{
                                    wordBreak: "break-word",
                                    whiteSpace: "pre-wrap",
                                    display: "block", // Ensure code element fits within pre
                                    width: "100%"
                                }} className={`language-${match[1]}`}>{children}</code>
                            </pre>
                        ) : (
                            <code style={{
                                wordBreak: "break-word",
                                whiteSpace: "pre-wrap", // Ensure inline code wraps properly
                            }} className={className} {...props}>
                                {children}
                            </code>
                        );
                    }
                }}
            />
        );
    }
}

export default Markdown;