import React, { Component } from "react"
import { TextField, IconButton, Typography, Tooltip } from "@mui/material"
import { AddPhotoAlternate, Close, Code, FormatBold, FormatItalic, FormatListBulleted, FormatListNumbered, FormatQuote, FormatStrikethrough, Link, Menu, Preview, PreviewOutlined, PreviewTwoTone, RadioButtonChecked, Subscript, Superscript, TableChart, VideoCall, VisibilityOff, VisibilityTwoTone } from "@mui/icons-material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CDNBrowser from "../pages/CDN/index";
import Util from "../util";
import Image from "./Image";
import Markdown from "./Markdown";

export default class MarkdownEditor extends Component {
	constructor(props) {
		super(props);
		this.textInput = React.createRef();
		this.state = {
			value: this.props.value,
			undoStack: [],
			redoStack: [],
			linkModalVisible: false,
			link: {
				url: "",
				displayText: ""
			},
			imageModalVisible: !true,
			imageModalSelectedSource: -1,
			fileSelectDialogVisible: false,
			isPreviewOn: true,
			visibleValue: this.props.value
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.value !== this.state.value) {
			this.setState({
				value: nextProps.value
			})
		}
		return true;
	}

	componentDidUpdate() {
		if (this.state.value !== this.props.value) {
			this.setState({
				value: this.props.value
			})
		}
	}

	handleKeyDown = (e) => {
		if (e.key === 'Tab') {
			e.preventDefault();
			const start = e.target.selectionStart;
			const end = e.target.selectionEnd;
			const value = e.target.value;
			const newValue = value.substring(0, start) + '\t' + value.substring(end);
			this.setState((prevState) => ({
				value: newValue,
				undoStack: [...prevState.undoStack, prevState.value],
				redoStack: [],
			}), () => {
				e.target.selectionStart = e.target.selectionEnd = start + 1;
			});
		} else if (e.ctrlKey && e.key === 'z') {
			e.preventDefault();
			if (this.state.undoStack.length > 0) {
				const lastValue = this.state.undoStack.pop();
				this.setState((prevState) => ({
					value: lastValue,
					redoStack: [prevState.value, ...prevState.redoStack],
				}));
			}
		} else if (e.ctrlKey && e.key === 'y') {
			e.preventDefault();
			if (this.state.redoStack.length > 0) {
				const nextValue = this.state.redoStack.shift();
				this.setState((prevState) => ({
					value: nextValue,
					undoStack: [...prevState.undoStack, prevState.value],
				}));
			}
		}
	};

	handleChange = (e) => {
		const newValue = e.target.value;
		this.props.onChange(newValue)
		this.setState((prevState) => ({
			value: newValue,
			undoStack: [...prevState.undoStack, prevState.value],
			redoStack: [],
		}));

		if (this.state.isPreviewOn) {
			this.setState({
				visibleValue: newValue
			})
		}
	};

	handleBoldClick = (event) => {
		event.preventDefault();

		const textarea = this.textInput.current;
		const start = textarea.selectionStart;
		const end = textarea.selectionEnd;
		const text = textarea.value;
		const before = text.substring(0, start);
		const selected = text.substring(start, end);
		const after = text.substring(end);

		let newValue;
		let newEnd;
		if (selected.startsWith('**') && selected.endsWith('**')) {
			newValue = before + selected.slice(2, -2) + after;
			newEnd = end - 4;
		} else {
			newValue = before + '**' + selected + '**' + after;
			newEnd = end + 4;
		}

		this.props.onChange(newValue)
		this.setState((prevState) => ({
			value: newValue,
			undoStack: [...prevState.undoStack, prevState.value],
			redoStack: [],
		}), () => {
			textarea.selectionStart = start;
			textarea.selectionEnd = newEnd;
		});
	};

	handleItalicClick = (event) => {
		event.preventDefault();

		const textarea = this.textInput.current;
		const start = textarea.selectionStart;
		const end = textarea.selectionEnd;
		const text = textarea.value;
		const before = text.substring(0, start);
		const selected = text.substring(start, end);
		const after = text.substring(end);

		let newValue;
		let newEnd;
		if (selected.startsWith('_') && selected.endsWith('_')) {
			newValue = before + selected.slice(1, -1) + after;
			newEnd = end - 2;
		} else {
			newValue = before + '_' + selected + '_' + after;
			newEnd = end + 2;
		}

		this.props.onChange(newValue)
		this.setState((prevState) => ({
			value: newValue,
			undoStack: [...prevState.undoStack, prevState.value],
			redoStack: [],
		}), () => {
			textarea.selectionStart = start;
			textarea.selectionEnd = newEnd;
		});
	};

	handleStrikethroughClick = (event) => {
		event.preventDefault();

		const textarea = this.textInput.current;
		const start = textarea.selectionStart;
		const end = textarea.selectionEnd;
		const text = textarea.value;
		const before = text.substring(0, start);
		const selected = text.substring(start, end);
		const after = text.substring(end);

		let newValue;
		let newEnd;
		if (selected.startsWith('~~') && selected.endsWith('~~')) {
			newValue = before + selected.slice(2, -2) + after;
			newEnd = end - 4;
		} else {
			newValue = before + '~~' + selected + '~~' + after;
			newEnd = end + 4;
		}

		this.props.onChange(newValue)
		this.setState((prevState) => ({
			value: newValue,
			undoStack: [...prevState.undoStack, prevState.value],
			redoStack: [],
		}), () => {
			textarea.selectionStart = start;
			textarea.selectionEnd = newEnd;
		});
	};

	handleCodeClick = (event) => {
		event.preventDefault();

		const textarea = this.textInput.current;
		const start = textarea.selectionStart;
		const end = textarea.selectionEnd;
		const text = textarea.value;
		const before = text.substring(0, start);
		const selected = text.substring(start, end);
		const after = text.substring(end);

		let newValue;
		let newEnd;
		if (selected.startsWith('```') && selected.endsWith('```')) {
			newValue = before + selected.slice(3, -3) + after;
			newEnd = end - 6;
		} else {
			newValue = before + '```' + selected + '```' + after;
			newEnd = end + 6;
		}

		this.props.onChange(newValue)
		this.setState((prevState) => ({
			value: newValue,
			undoStack: [...prevState.undoStack, prevState.value],
			redoStack: [],
		}), () => {
			textarea.selectionStart = start;
			textarea.selectionEnd = newEnd;
		});
	};

	handleFormatListBulletedClick = (event) => {
		event.preventDefault();

		const textarea = this.textInput.current;
		const start = textarea.selectionStart;
		const end = textarea.selectionEnd;
		const text = textarea.value;
		const before = text.substring(0, start);
		const selected = text.substring(start, end);
		const after = text.substring(end);

		let newValue;
		let newEnd;
		if (selected.startsWith('- ')) {
			// Unformat the bulleted list
			newValue = before + selected.slice(2) + after;
			newEnd = end - 2;
		} else {
			// Format the bulleted list
			newValue = before + '- ' + selected + after;
			newEnd = end + 2;
		}

		this.props.onChange(newValue)
		this.setState((prevState) => ({
			value: newValue,
			undoStack: [...prevState.undoStack, prevState.value],
			redoStack: [],
		}), () => {
			textarea.selectionStart = start;
			textarea.selectionEnd = newEnd;
		});
	};

	handleFormatListNumberedClick = (event) => {
		event.preventDefault();

		const textarea = this.textInput.current;
		const start = textarea.selectionStart;
		const end = textarea.selectionEnd;
		const text = textarea.value;
		const before = text.substring(0, start);
		const selected = text.substring(start, end);
		const after = text.substring(end);

		let newValue;
		let newEnd;
		if (selected.startsWith('1. ')) {
			// Unformat the numbered list
			newValue = before + selected.slice(3) + after;
			newEnd = end - 3;
		} else {
			// Format the numbered list
			newValue = before + '1. ' + selected + after;
			newEnd = end + 3;
		}

		this.props.onChange(newValue)
		this.setState((prevState) => ({
			value: newValue,
			undoStack: [...prevState.undoStack, prevState.value],
			redoStack: [],
		}), () => {
			textarea.selectionStart = start;
			textarea.selectionEnd = newEnd;
		});
	};

	handleFormatQuoteClick = (event) => {
		event.preventDefault();

		const textarea = this.textInput.current;
		const start = textarea.selectionStart;
		const end = textarea.selectionEnd;
		const text = textarea.value;
		const before = text.substring(0, start);
		const selected = text.substring(start, end);
		const after = text.substring(end);

		let newValue;
		let newEnd;
		if (selected.startsWith('> ')) {
			// Unformat the quote
			newValue = before + selected.slice(2) + after;
			newEnd = end - 2;
		} else {
			// Format the quote
			newValue = before + '> ' + selected + after;
			newEnd = end + 2;
		}

		this.props.onChange(newValue)
		this.setState((prevState) => ({
			value: newValue,
			undoStack: [...prevState.undoStack, prevState.value],
			redoStack: [],
		}), () => {
			textarea.selectionStart = start;
			textarea.selectionEnd = newEnd;
		});
	};

	handleTableChartClick = (event) => {
		event.preventDefault();

		const textarea = this.textInput.current;
		const start = textarea.selectionStart;
		const end = textarea.selectionEnd;
		const text = textarea.value;
		const before = text.substring(0, start);
		const after = text.substring(end);

		// Define an empty table
		const table = `
| Column Title 1 | Column Title 2 |
|-|-|
| Column Row | Column Row |
`;

		let newValue = before + table + after;
		let newEnd = end + table.length;

		this.props.onChange(newValue)
		this.setState((prevState) => ({
			value: newValue,
			undoStack: [...prevState.undoStack, prevState.value],
			redoStack: [],
		}), () => {
			textarea.selectionStart = start;
			textarea.selectionEnd = newEnd;
		});
	};

	handleInsertLinkClick = () => {
		this.setState({
			linkModalVisible: true
		})
	}

	handleInsertLinkModalClick = () => {
		const textarea = this.textInput.current;
		const start = textarea.selectionStart;
		const end = textarea.selectionEnd;
		const text = textarea.value;
		const before = text.substring(0, start);
		const after = text.substring(end);

		const link = `[${this.state.link.displayText || this.state.link.url}](${this.state.link.url})`;

		let newValue = before + link + after;
		let newEnd = end + link.length;

		this.props.onChange(newValue)
		this.setState((prevState) => ({
			value: newValue,
			undoStack: [...prevState.undoStack, prevState.value],
			redoStack: [],
		}), () => {
			textarea.selectionStart = start;
			textarea.selectionEnd = newEnd;
		});
	}

	handleInsertImageClick = () => {
		this.setState({
			imageModalVisible: true
		})
	}

	handleInsertImageModalClick = () => {
		const textarea = this.textInput.current;
		const start = textarea.selectionStart;
		const end = textarea.selectionEnd;
		const text = textarea.value;
		const before = text.substring(0, start);
		const after = text.substring(end);

		const link = `![${this.state.link.displayText || "image alt text"}](${this.state.link.url})`;

		let newValue = before + link + after;
		let newEnd = end + link.length;

		this.props.onChange(newValue)
		this.setState((prevState) => ({
			value: newValue,
			undoStack: [...prevState.undoStack, prevState.value],
			redoStack: [],
		}), () => {
			textarea.selectionStart = start;
			textarea.selectionEnd = newEnd;
		});
	}

	handleSpoilerClick = (event) => {
		event.preventDefault();

		const textarea = this.textInput.current;
		const start = textarea.selectionStart;
		const end = textarea.selectionEnd;
		const text = textarea.value;
		const before = text.substring(0, start);
		const selected = text.substring(start, end);
		const after = text.substring(end);

		let newValue;
		let newEnd;

		// Wrap the spoiler
		newValue = before + `<details>\n<summary>Spoiler Title</summary>\n${selected}\n</details>` + after;
		newEnd = end + 54;

		this.props.onChange(newValue)
		this.setState((prevState) => ({
			value: newValue,
			undoStack: [...prevState.undoStack, prevState.value],
			redoStack: [],
		}), () => {
			textarea.selectionStart = start;
			textarea.selectionEnd = newEnd;
		});
	};

	render() {
		return (
			<div>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						gap: 20,
						position: "relative",
					}}
				>
					<Dialog
						open={this.state.fileSelectDialogVisible}
						onClose={() => {
							this.setState({
								fileSelectDialogVisible: false
							})
						}}
						fullScreen
					>
						<IconButton
							onClick={() => {
								this.setState({
									fileSelectDialogVisible: false
								})
							}}
							style={{
								position: "absolute",
								right: 10,
								top: 10
							}}
						>
							<Close />
						</IconButton>

						<DialogTitle>Select File</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Select or upload a file to insert into the markdown editor. You can also drag and drop files into the box below.
							</DialogContentText>
							<CDNBrowser
								isSelectDialog={true}
								selectItem={(item) => {
									this.setState({
										link: {
											url: item.url,
											displayText: item.filename
										},
										fileSelectDialogVisible: false,
										imageModalVisible: false,
									})

									setTimeout(() => {
										this.handleInsertImageModalClick();

										this.setState({
											link: {
												url: "",
												displayText: ""
											}
										})
									}, 100);
								}}
								close={() => {

								}}
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => {
								this.setState({
									fileSelectDialogVisible: false
								})
							}}>Cancel</Button>
						</DialogActions>
					</Dialog>

					<Dialog
						open={this.state.linkModalVisible}
						onClose={() => {
							this.setState({
								linkModalVisible: false
							})
						}}
						PaperProps={{

						}}
					>
						<DialogTitle>Insert Link</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Enter the link you'd like to insert into the box below, optionally you can also change the text that will be displayed for the link.
							</DialogContentText>
							<TextField
								autoFocus
								required
								margin="dense"
								label="Link"
								type="url"
								fullWidth
								variant="standard"
								value={this.state.link.url}
								onChange={(e) => { this.setState({ link: { ...this.state.link, url: e.target.value } }) }}
							/>
							<TextField
								margin="dense"
								label="Display Text (Optional)"
								type="text"
								fullWidth
								variant="standard"
								value={this.state.link.displayText}
								onChange={(e) => { this.setState({ link: { ...this.state.link, displayText: e.target.value } }) }}
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => {
								this.setState({
									linkModalVisible: false,
									link: {
										url: "",
										displayText: ""
									}
								})
							}}>Cancel</Button>
							<Button
								onClick={() => {
									this.handleInsertLinkModalClick();
									this.setState({
										linkModalVisible: false,
										link: {
											url: "",
											displayText: ""
										}
									})
								}}
								type="submit">Insert</Button>
						</DialogActions>
					</Dialog>

					<Dialog
						open={this.state.imageModalVisible}
						onClose={() => {
							this.setState({
								imageModalVisible: false
							})
						}}
						PaperProps={{

						}}
					>
						<DialogTitle>Insert Image</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Enter the link to the image you'd like to insert into the box below.<br /><br />
								External images will be converted to VorecadeCDN links upon clicking "Upload" After saved, updates to the external image will not be reflected in Vorecade. You'll need to re-attach the external image here to see the changes.<br /><br />We recommend you to use VorecadeCDN for images that are frequently updated.
							</DialogContentText>

							<FormControl
								fullWidth
								variant="standard"
								margin="dense"
							>
								<Select
									value={this.state.imageModalSelectedSource}
									onChange={(e) => { this.setState({ imageModalSelectedSource: e.target.value }) }}
								>
									<MenuItem value={-1} disabled>Image Source</MenuItem>
									<MenuItem value={0}>Image URL</MenuItem>
									<MenuItem value={1}>Upload Image</MenuItem>
								</Select>
							</FormControl>

							{
								this.state.imageModalSelectedSource === 0 ? (
									<div>
										<TextField
											autoFocus
											required
											margin="dense"
											label="Image Link"
											type="url"
											fullWidth
											variant="standard"
											value={this.state.link.url}
											onChange={(e) => { this.setState({ link: { ...this.state.link, url: e.target.value } }) }}
										/>

										<TextField
											margin="dense"
											label="Display Text (Optional)"
											type="text"
											fullWidth
											variant="standard"
											value={this.state.link.displayText}
											onChange={(e) => { this.setState({ link: { ...this.state.link, displayText: e.target.value } }) }}
										/>
									</div>
								) :
									this.state.imageModalSelectedSource === 1 ? (
										<div>
											<input
												type="file"
												accept="image/*"
												onChange={(e) => {
													const formData = new FormData();
													let fID = (Math.random() * 1000000).toString()
													formData.append('file', e.target.files[0]);
													formData.append('visible', true);
													formData.append('name', "autoUploaded_" + e.target.files[0].name);
													formData.append("vcid", fID)

													// check if the attached files folder exists
													// if not, create it
													// then upload the file
													Util.postRequest("/api/content/myFiles").then(async (res) => {
														let folders = res.data
														let folderExists = false
														let folderID = ""
														for (let i = 0; i < folders.length; i++) {
															if (folders[i].filename.startsWith("AttachedFiles")) {
																folderExists = true
																folderID = folders[i]._id
															}
														}

														if (!folderExists) {
															folderID = (await Util.postRequest("/api/content/createFolder", { filename: "AttachedFiles", parent: "/" })).folder._id
														}

														formData.append("parent", folderID)

														let previewURL = (await Util.postRequest("/api/content/upload", formData)).url

														this.setState({ imageModalSelectedSource: 0, link: { url: previewURL, displayText: "" } })
													})

												}}
												id="markdownEditorImageUploadInput"
												style={{
													display: "none"
												}}
											/>
											<Button
												onClick={() => {
													this.setState({
														fileSelectDialogVisible: true
													})
													//document.getElementById("markdownEditorImageUploadInput").click();
												}}
											>Select Image</Button>
										</div>
									) : null
							}
						</DialogContent>
						<DialogActions>
							<Button onClick={() => {
								this.setState({
									imageModalVisible: false,
									link: {
										url: "",
										displayText: ""
									}
								})
							}
							}>Cancel</Button>

							<Button
								disabled={this.state.imageModalSelectedSource === -1}
								onClick={() => {
									this.handleInsertImageModalClick();
									this.setState({
										imageModalVisible: false,
										link: {
											url: "",
											displayText: ""
										}
									})
								}}
								type="submit">Insert</Button>
						</DialogActions>
					</Dialog>
					<div
						style={{
							width: "50%",
							alignSelf: "flex-start",
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							alignItems: "flex-start",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<Tooltip title="Bold">
								<IconButton onMouseDown={event => event.preventDefault()}
									onClick={this.handleBoldClick}>
									<FormatBold />
								</IconButton>
							</Tooltip>

							<Tooltip title="Italic">
								<IconButton onMouseDown={event => event.preventDefault()}
									onClick={this.handleItalicClick}>
									<FormatItalic />
								</IconButton>
							</Tooltip>

							<Tooltip title="Strikethrough">
								<IconButton onMouseDown={event => event.preventDefault()}
									onClick={this.handleStrikethroughClick}>
									<FormatStrikethrough />
								</IconButton>
							</Tooltip>

							<Tooltip title="Code Block">
								<IconButton onMouseDown={event => event.preventDefault()}
									onClick={this.handleCodeClick}
								>
									<Code />
								</IconButton>
							</Tooltip>

							<Tooltip title="Bulleted List">
								<IconButton onMouseDown={event => event.preventDefault()}
									onClick={this.handleFormatListBulletedClick}
								>
									<FormatListBulleted />
								</IconButton>
							</Tooltip>

							<Tooltip title="Numbered List">
								<IconButton onMouseDown={event => event.preventDefault()}
									onClick={this.handleFormatListNumberedClick}
								>
									<FormatListNumbered />
								</IconButton>
							</Tooltip>

							<Tooltip title="Quote">
								<IconButton onMouseDown={event => event.preventDefault()}
									onClick={this.handleFormatQuoteClick}
								>
									<FormatQuote />
								</IconButton>
							</Tooltip>

							<Tooltip title="Link">
								<IconButton onMouseDown={event => event.preventDefault()}
									onClick={this.handleInsertLinkClick}
								>
									<Link />
								</IconButton>
							</Tooltip>

							<Tooltip title="Table">
								<IconButton onMouseDown={event => event.preventDefault()}
									onClick={this.handleTableChartClick}
								>
									<TableChart />
								</IconButton>
							</Tooltip>

							<Tooltip title="Image">
								<IconButton onMouseDown={event => event.preventDefault()}
									onClick={this.handleInsertImageClick}
								>
									<AddPhotoAlternate />
								</IconButton>
							</Tooltip>

							<Tooltip title="Spoiler">
								<IconButton onMouseDown={event => event.preventDefault()}
									onClick={this.handleSpoilerClick}
								>
									<VisibilityOff />
								</IconButton>
							</Tooltip>

							<Tooltip title="Preview">
								<IconButton onMouseDown={event => event.preventDefault()}
									onClick={() => {
										this.setState({
											isPreviewOn: !this.state.isPreviewOn
										})
									}}
								>
									{this.state.isPreviewOn ? <Preview /> : <PreviewTwoTone />}
								</IconButton>
							</Tooltip>
						</div>

						<TextField
							multiline
							minRows={10}
							value={this.state.value}
							onKeyDown={this.handleKeyDown}
							onChange={this.handleChange}
							style={{
								width: "100%",
							}}
							inputRef={this.textInput}
						/>
					</div>

					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "50%",
							marginTop: 10,
							wordBreak: "break-word",
							textAlign: "left",
						}}
					>
						<Typography component={"p"} variant={"h3"} sx={{
							fontSize: 20,
							fontWeight: "bold",
							marginBottom: -1,
							textAlign: "left",
							position: "absolute",
							top: 5,
						}}>Live Preview</Typography>
						<Markdown
							markdown={this.state.isPreviewOn ? this.props.value : `
# PREVIEW TURNED OFF
								`}
						/>
					</div>



				</div>
			</div>
		)
	}
}