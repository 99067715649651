import { Typography, Box, Select, MenuItem, Accordion, AccordionSummary, IconButton, Collapse, TextField, Dialog, DialogContent, DialogContentText, Checkbox, FormGroup, FormControl, FormControlLabel, Autocomplete, createFilterOptions, Tooltip, DialogActions } from '@mui/material';
import Button from '@mui/material/Button';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import util from '../../util';
import { ChartsReferenceLine, ChartsXAxis, ChartsYAxis, LineChart, LinePlot, MarkPlot } from '@mui/x-charts';
import { ArrowDropDown, ArrowDropUp, Check, Close, InfoOutlined } from '@mui/icons-material';
import { loadingCaller } from '../../App';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

// Utility functions to subtract years, months, weeks, and days from the current date
const subtractDate = (type, amount) => {
    const date = new Date();
    switch (type) {
        case 'years':
            date.setFullYear(date.getFullYear() - amount);
            break;
        case 'months':
            date.setMonth(date.getMonth() - amount);
            break;
        case 'weeks':
            date.setDate(date.getDate() - (amount * 7));
            break;
        case 'days':
            date.setDate(date.getDate() - amount);
            break;
        default:
            break;
    }
    return date;
};

const filterDataByTimePeriod = (data, timePeriod) => {
    let thresholdDate;
    switch (timePeriod) {
        case '2 years':
            thresholdDate = subtractDate('years', 2);
            break;
        case '1 year':
            thresholdDate = subtractDate('years', 1);
            break;
        case '6 months':
            thresholdDate = subtractDate('months', 6);
            break;
        case '3 months':
            thresholdDate = subtractDate('months', 3);
            break;
        case '1 month':
            thresholdDate = subtractDate('months', 1);
            break;
        case '1 week':
            thresholdDate = subtractDate('weeks', 1);
            break;
        case '3 days':
            thresholdDate = subtractDate('days', 3);
            break;
        case '1 day':
            thresholdDate = subtractDate('days', 1);
            break;
        default:
            return data; // Lifetime or default case
    }
    return data.filter((x) => new Date(x._id) >= thresholdDate);
};

const getStartDateFromPeriod = (period) => {
    const currentDate = new Date();
    switch (period) {
        case "Lifetime":
            return new Date(0); // Return epoch date for lifetime
        case "2 years":
            currentDate.setFullYear(currentDate.getFullYear() - 2);
            break;
        case "1 year":
            currentDate.setFullYear(currentDate.getFullYear() - 1);
            break;
        case "6 months":
            currentDate.setMonth(currentDate.getMonth() - 6);
            break;
        case "3 months":
            currentDate.setMonth(currentDate.getMonth() - 3);
            break;
        case "1 month":
            currentDate.setMonth(currentDate.getMonth() - 1);
            break;
        case "1 week":
            currentDate.setDate(currentDate.getDate() - 7);
            break;
        case "3 days":
            currentDate.setDate(currentDate.getDate() - 3);
            break;
        case "1 day":
            currentDate.setDate(currentDate.getDate() - 1);
            break;
        default:
            return currentDate; // Return current date as default
    }
    return currentDate.toISOString().split('T')[0]; // Return date in 'YYYY-MM-DD' format
};

const Home = () => {
    const navigate = useNavigate();

    const [value, setValue] = useState(0);

    const [expandedRow, setExpandedRow] = useState(null);

    const [playGraphData, setPlayGraphData] = useState([]);
    const [userGraphData, setUserGraphData] = useState([]);

    useEffect(() => {
        util.postRequest("/api/charts/getInGamePlayers168Hours").then((IGP) => {
            util.postRequest("/api/charts/getUsers168Hours").then((AU) => {
                // make sure the data is the same length, if not, equalize it
                const length = Math.min(IGP.data.length, AU.data.length);
                setPlayGraphData(IGP.data.slice(0, length));
                setUserGraphData(AU.data.slice(0, length));                
            })
        })
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function formatSessionLength(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds - (hours * 3600)) / 60);
        const remainingSeconds = seconds - (hours * 3600) - (minutes * 60);

        let result = "";
        if (hours > 0) {
            result += `${hours}h `;
        }
        if (minutes > 0 || hours > 0) { // Include minutes if there are hours
            result += `${minutes}m `;
        }
        result += `${remainingSeconds.toFixed(0)}s`;

        return result.trim();
    }

    return (
        <Box style={{ marginTop: 25, paddingBottom: 80, maxWidth: "xs" }}>
            <Typography
                style={{
                    color: "#fff",
                    fontSize: 22,
                    fontFamily: "Russo One",
                    zIndex: 1,

                }}
            >
                Vorecade Analytics
            </Typography>


            <Typography
                style={{
                    color: "#fff",
                    fontSize: 22,
                    fontFamily: "Russo One",
                    zIndex: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 20
                }}
            >
                Player and User Activity

                <Tooltip title="Due to measurement errors with anonymous users, sometimes Users can be less than the current games played.">
                    <InfoOutlined
                        style={{
                            color: "#fff",
                            fontSize: 22,
                            fontFamily: "Russo One",
                            zIndex: 1,
                            marginLeft: 10
                        }}
                    />
                </Tooltip>
            </Typography>

            <LineChart
                xAxis={[{
                    data: playGraphData?.map(x => new Date(x.hour)),
                    scaleType: 'time',
                },
                {
                    data: userGraphData?.map(x => new Date(x.hour)),
                    scaleType: 'time',
                }
                ]}

                skipAnimation={true}
                series={[
                    {
                        data: playGraphData?.map((x) => x.count),
                        label: "   In-Game Players",
                        color: "#28c91699",
                        type: "line",
                        showMark: false
                    },
                    {
                        data: userGraphData?.map((x) => x.activeClients),
                        label: "   Users",
                        color: "#aa490099",
                        type: "line",
                        showMark: false,
                    }
                ]}
                height={400}
                sx={{
                    fontFamily: "Russo One",
                }}
                grid={{
                    vertical: false,
                    horizontal: false
                }}
            >
                <LinePlot />
                <MarkPlot />
                <ChartsXAxis />
                <ChartsYAxis />
            </LineChart>

            <Typography
                style={{
                    color: "#fff",
                    fontSize: 22,
                    fontFamily: "Russo One",
                    zIndex: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 20
                }}
            >
                I'll hopefully find more things to chart here soon.
            </Typography>
        </Box >
    );
};

export default Home;