import { Typography, Box } from '@mui/material';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CDNRoot from './CDN/index';
import GameRoot from "./GameEditor/index";
import GameAnalyticsRoot from "./GameAnalytics/index";
import DocumentationRoot from "./Documentation/documentationIndex";

import { isElectron } from '../util';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{  }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const Home = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user") || "false") || false);
    if (!user) navigate("/login");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box style={{ marginTop: 0, margin: 0, maxWidth: "xs" }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="My Files" />
                    <Tab label="Game Management" />
                    <Tab label="Documentation" />
                </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
                <CDNRoot />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <GameRoot />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
                <DocumentationRoot />
            </CustomTabPanel>
        </Box>
    );
};

export default Home;