import { useEffect, useRef, useState, Component } from 'react';

function getHolidayAbbreviation(date = new Date()) {
    const year = date.getFullYear();

    // Check for Halloween (October 31)
    if (date.getMonth() === 9 && date.getDate() === 31) {
        return 'HLW';
    }

    // Check for Christmas (Dec 25)
    if (date.getMonth() === 11 && date.getDate() === 25) {
        return 'CHR'; // Return CHR
    }

    // Check for winter effects (Dec 1 - Jan 1) - return WTR

    if (date.getMonth() === 11 && date.getDate() >= 1) {
        return 'WTR';
    }

    // Check for New Year's Day (Jan 1)
    if (date.getMonth() === 0 && date.getDate() === 1) {
        return 'NEW';
    }

    if (date.getMonth() === 10 && date.getDate() === 29) {
        return 'CYK';
    }

    return null; // No holiday for this date
}

const hexToRgb = (hex) => {
    // Remove the '#' at the start if present
    hex = hex.replace('#', '').toLowerCase()

    // Convert short hex format (#FFF) to full form (#FFFFFF)
    if (hex.length === 3) {
        hex = hex.split('').map((char) => char + char).join('');
    }

    // Extract RGB values
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    return [r, g, b];
};

const FireflyCanvasMain = ({ colors, flicker = true }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        if(colors?.length === 0) {
            return;
        }
        
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        // Function to update canvas size based on its parent
        const updateCanvasSize = () => {
            const parent = canvas.parentElement;
            const width = parent ? parent.clientWidth : window.innerWidth;
            const height = parent ? parent.clientHeight : window.innerHeight;
            canvas.width = width;
            canvas.height = height;
        };

        // Initial size update
        updateCanvasSize();

        // Use ResizeObserver to handle resizing dynamically
        const resizeObserver = new ResizeObserver(updateCanvasSize);
        resizeObserver.observe(canvas.parentElement);

        // Particle class
        class Particle {
            constructor(x, y, color) {
                this.x = x;
                this.y = y;
                this.size = Math.random() * 3 + 1;
                this.velocityX = Math.random() * 2 - 1; // Random horizontal velocity
                this.velocityY = Math.random() * 2 + 0.5; // Slow upward movement
                this.color = color;
                this.baseAlpha = Math.random() * 0.5 + 0.3; // Base alpha value for glow (adjusted to be more visible)
                this.maxGlowRadius = Math.random() * 10 + 1; // Max radius for glow effect
                this.alpha = this.baseAlpha; // Initial alpha for glow effect
                this.flickerDirection = Math.random() > 0.5 ? 1 : -1; // Random direction for flicker
            }

            update(deltaTime) {
                // Move the particle, scaling velocity by deltaTime for consistent speed
                this.x += this.velocityX * deltaTime;
                this.y -= this.velocityY * deltaTime; // Move upwards

                // Update the glow effect (flickering)
                if (flicker) {
                    // Flicker effect: randomly adjust glow intensity (alpha) to simulate flickering
                    this.alpha += (Math.random() * 0.05 - 0.025) * this.flickerDirection;
                    if (this.alpha < this.baseAlpha - 0.2) this.flickerDirection = 1; // Bounce the alpha up when it's too low
                    if (this.alpha > this.baseAlpha + 0.2) this.flickerDirection = -1; // Bounce the alpha down when it's too high
                } else {
                    // Smooth glow effect (without flicker)
                    this.alpha = this.baseAlpha;
                }
                this.alpha = Math.max(0.2, Math.min(this.alpha, 1)); // Keep alpha within bounds

                // Wrap around the canvas if the particle moves out of bounds
                if (this.x < 0) this.x = canvas.width;
                if (this.x > canvas.width) this.x = 0;
                if (this.y < -40) this.y = canvas.height;
                if (this.y > canvas.height) this.y = -40;
            }

            draw(ctx) {
                // Glow effect: Draw a large semi-transparent circle first (the glow)
                ctx.beginPath();
                ctx.arc(this.x, this.y, this.maxGlowRadius, 0, Math.PI * 2);
                ctx.fillStyle = `rgba(${this.color[0]}, ${this.color[1]}, ${this.color[2]}, ${this.alpha * 0.5})`;
                ctx.fill();

                // Particle core: Draw a smaller, solid circle for the firefly itself
                ctx.beginPath();
                ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
                ctx.fillStyle = `rgba(${this.color[0]}, ${this.color[1]}, ${this.color[2]}, ${this.alpha})`;
                ctx.fill();
            }
        }

        // Convert hex colors to RGB
        const rgbColors = colors.map(hexToRgb);

        // Create an array of particles
        const particles = [];
        const numParticles = 150;

        for (let i = 0; i < numParticles; i++) {
            const randomColor = rgbColors[Math.floor(Math.random() * rgbColors.length)];
            particles.push(new Particle(Math.random() * canvas.width, canvas.height * Math.random(), randomColor));
        }

        // Animation loop
        let lastTime = 0;

        const animate = (time) => {
            const deltaTime = (time - lastTime) / 16.6; // Animation speed is normalized to 60FPS (16.6ms)
            // 1000ms / 60FPS = 16.6ms 
            lastTime = time;

            ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas

            particles.forEach(particle => {
                particle.update(deltaTime);
                particle.draw(ctx);
            });

            animationFrameId = requestAnimationFrame(animate); // Recursively call the animate function for the next frame
        };

        animate(0); // Start the animation

        // Cleanup function
        return () => {
            cancelAnimationFrame(animationFrameId); // Cancel the animation frame when the component unmounts
            resizeObserver.disconnect(); // Clean up the ResizeObserver
        };
    }, [colors, flicker]); // Only rerun effect when colors or flicker option changes

    if (colors.length === 0) {
        return <></>
    }

    let animationFrameId = null; // To store the ID of the animation frame

    

    return <canvas style={{
        transform: "rotate(180deg)",
    }} ref={canvasRef}></canvas>;
};

export class SnowfallCanvas extends Component {
    constructor(props) {
        super(props);

        // determine the color to use based on the current date

        let holidayAbbreviation = getHolidayAbbreviation();

        console.log(holidayAbbreviation)

        switch (holidayAbbreviation) {
            case "HLW":
                this.state = {
                    colors: [
                        "#FFA500", // Orange
                        "#FF6347", // Tomato
                        "#FF6A13", // Bright Orange
                        "#FF4500", // Orange Red
                        "#FF7F00", // Dark Orange
                        "#FF8C00", // Darker Orange
                        "#FFB84D", // Light Orange
                        "#FF9933", // Light Orange 2
                        "#FF6F00"  // Strong Orange
                    ]
                }
                break;

            case "CHR":
                this.state = {
                    colors: [
                        "#FFFFFF", // White
                        "#FF0000", // Red
                        "#00FF00", // Green
                        "#0000FF", // Blue
                        "#FFFF00", // Yellow
                        "#FF00FF", // Magenta
                        "#00FFFF", // Cyan
                        "#FF4500", // Orange Red
                        "#FF6347"  // Tomato
                    ]
                }
                break;

            case "WTR":
                this.state = {
                    colors: [
                        "#ffffff",
                        "#f0f0f0",
                        "#d9d9d9",
                        "#bfbfbf",
                        "#a6a6a6",
                        "#8c8c8c",
                        "#737373",
                        "#595959",
                        "#404040",
                        "#262626"
                    ]
                }
                break;

            case "NEW":
                this.state = {
                    colors: [
                        "#FFFFFF", // White
                        "#FFD700", // Gold
                        "#FF4500", // Orange Red
                        "#FF6347", // Tomato
                        "#FF6A13", // Bright Orange
                        "#FF7F00", // Dark Orange
                        "#FF8C00", // Darker Orange
                        "#FFA500", // Orange
                        "#FFB84D"  // Light Orange
                    ]
                }
                break;

            case "CNY":
                this.state = {
                    colors: [
                        "#FF0000", // Red
                        "#FF6347", // Tomato
                        "#FF6A13", // Bright Orange
                        "#FF4500", // Orange Red
                        "#FF7F00", // Dark Orange
                        "#FF8C00", // Darker Orange
                        "#FFB84D", // Light Orange
                        "#FF9933", // Light Orange 2
                        "#FF6F00"  // Strong Orange
                    ]
                }
                break;

            case "CYK":
                this.state = {
                    // purple-ish
                    colors: [
                        "#FF00FF",
                        "#8A2BE2",
                        "#9932CC",
                        "#9400D3",
                        "#8B008B",
                        "#800080",
                        "#9370DB",
                        "#DDA0DD",
                        "#EE82EE",
                        "#DA70D6"
                    ]
                }
                break;

            default:
            case null:
                this.state = {
                    colors: [

                    ]
                }
                break;
        }
    }

    shouldComponentUpdate(nextProps) {
        return false;
    }

    render() {
        return (
            <FireflyCanvasMain colors={this.state.colors} flicker={this.props.flicker} />
        );
    }
}
