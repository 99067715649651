import Button from '@mui/material/Button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import util from '../util';
import { Typography } from '@mui/material';

const Home = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Button
                onClick={() => {
                    window.open("https://cdn.vorecade.com/defaults/downloads/clientVersionDownloads/Vorecade%20v30.zip", "_blank");
                }}
            >download</Button>
        </div>
    );
};

export default Home;